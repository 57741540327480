<template>
  <n-card>
    <div class="flex flex-row">
      <n-button
        type="quaternary"
        ghost
        @click="handleShowAreas"
      >
        <template #icon>
          <n-icon>
            <RightArrowIcon />
          </n-icon>
        </template>
      </n-button>
      <div class="my-auto ml-3">
        {{ t('components.areas.button.showAll') }}
      </div>
    </div>
  </n-card>
</template>

<script lang="ts" setup>
import {ChevronRight as RightArrowIcon} from '@vicons/fa'
import type {PropType} from 'vue'
import {h} from 'vue'
import {useDialog} from 'naive-ui'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import AreaList from '@/components/areas/AreaList.vue'

const {t} = useI18n()

const props = defineProps({
  fetchList: {
    type: Function as PropType<() => void>,
    default: () => [],
    required: true,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false,
    required: true,
  },
})

const store = useStore()

const dialog = useDialog()

const handleShowAreas = () => {
  props.fetchList()

  return dialog.info({
    title: 'Gebiete',
    content: () => {
      return h(AreaList, {
        data: store.getters['Areas/getAreaList'],
        fetchList: props.fetchList,
        loading: store.getters['Areas/areasLoading'],
      })
    },
    style: {
      'min-width': 'auto',
      'width': 'auto',
      'margin-left': '150px',
      'margin-right': '150px',
      'max-width': 'none',
    },
  })
}
</script>

<style scoped>
</style>
