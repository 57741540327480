import type {GetterTree} from 'vuex'
import type {RootState} from '@/store'
import type {SettingsState} from '@/store/modules/settings/index'

const getters: GetterTree<SettingsState, RootState> = {
  getYear: (state): SettingsState['year'] => state.year,
  getCountry: (state): SettingsState['country'] => state.country,
  getGeoRestriction: (state): SettingsState['geoRestrict'] => state.geoRestrict,
}

export default getters
