import type {DataTableColumn} from 'naive-ui'
import {NButton, NIcon} from 'naive-ui'
import {useStore} from 'vuex'
import {h} from 'vue'
import {Delete16Regular} from '@vicons/fluent'
import {useI18n} from 'vue-i18n'
import {BoundConditionType, SurfaceType} from '@/api/modules/boundConditions'
import type {BoundCondition} from '@/api/modules/boundConditions'

type Row = BoundCondition

export default () => {
  const store = useStore()

  const {t} = useI18n()

  const getColumns: (deleteFunction: (id: BoundCondition['id']) => void) => Array<DataTableColumn<Row>> = (deleteFunction) => {
    return [
      {
        title: t('components.boundConditions.table.type'),
        key: 'type',
        render: (boundCondition: BoundCondition) => {
          return BoundConditionType[boundCondition.type]
        },
        width: 175,
      },
      {
        title: t('components.boundConditions.table.company'),
        key: 'company',
        render: (boundCondition: BoundCondition) => {
          return store.getters['Companies/getCompanyName'](boundCondition.company)
        },
        width: 150,
        ellipsis: {
          tooltip: true,
        },
      },
      {
        title: t('components.boundConditions.table.constraint'),
        key: 'rightConstraint',
        render: (boundCondition: BoundCondition) => {
          if (boundCondition.type === 'must_have_area' || boundCondition.type === 'cannot_have_area') { return store.getters['Areas/getAreaName'](boundCondition.area) }
          else if (boundCondition.type === 'minimum_meters') { return `${boundCondition.minimumM?.toLocaleString('de-DE')} m` }
          else if (boundCondition.type === 'max_surface_ratio') {
            const surfaceType = boundCondition.surfaceType ? SurfaceType[boundCondition.surfaceType] : ''
            return `${boundCondition.ratio ? boundCondition.ratio * 100 : ''}% ${surfaceType}`
          }
          else { return boundCondition.minimumArea }
        },
        width: 130,
        ellipsis: {
          tooltip: true,
        },
      },
      {
        title: '',
        key: 'actions',
        align: 'right',
        render: (boundCondition: BoundCondition) => {
          return h(NButton, {
            size: 'small',
            ghost: true,
            onClick: () => deleteFunction(boundCondition.id),
          },
          {
            default: () => h(NIcon, {
              size: 20,
            }, () => h(Delete16Regular)),
          })
        },
      }]
  }

  return {
    getColumns,
  }
}
