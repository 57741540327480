import type {GetterTree} from 'vuex'
import type {RootState} from '@/store'
import type {BoundConditionState} from '@/store/modules/boundConditions/index'
import type {BoundCondition} from '@/api/modules/boundConditions'

const getters: GetterTree<BoundConditionState, RootState> = {
  getBoundConditions: (state): Array<BoundCondition> => state.data,
  boundConditionsLoading: (state): boolean => state.loading,
}

export default getters
