import type {ActionTree} from 'vuex'
import type {RootState} from '@/store'
import type {SettingsState} from '@/store/modules/settings/index'

const actions: ActionTree<SettingsState, RootState> = {
  SET_COUNTRY: ({commit}, country: SettingsState['country']) => {
    commit('SET_COUNTRY', country)
  },
  SET_YEAR: ({commit}, year: SettingsState['year']) => {
    commit('SET_YEAR', year)
  },
  SET_GEO_RESTRICTION: ({commit}, geoRestrict: SettingsState['geoRestrict']) => {
    commit('SET_GEO_RESTRICTION', geoRestrict)
  },
}

export default actions
