import type {ActionTree} from 'vuex'
import type {RootState} from '@/store'
import type {BoundConditionState} from '@/store/modules/boundConditions/index'
import type {BoundCondition} from '@/api/modules/boundConditions'
import api from '@/api'

const actions: ActionTree<BoundConditionState, RootState> = {
  SET_BOUND_CONDITION_LIST: ({commit}) => {
    commit('SET_LOADING', true)
    api.boundConditions.fetchBoundConditions().then((res) => {
      commit('SET_BOUND_CONDITION_LIST', res.data)
    }).finally(() => {
      commit('SET_LOADING', false)
    })
  },
  ADD_BOUND_CONDITION: ({commit}, boundCondition: BoundCondition) => {
    commit('ADD_BOUND_CONDITION', boundCondition)
  },
  DELETE_BOUND_CONDITION: ({commit}, id: BoundCondition['id']) => {
    commit('SET_LOADING', true)
    commit('DELETE_BOUND_CONDITION', id)
  },
}

export default actions
