<template>
  <n-card
    title="Aktive Constraints"
    header-style="margin: auto"
    :segmented="{ content: true, footer: 'soft' }"
  >
    <n-scrollbar
      trigger="none"
      style="max-height: 200px"
    >
      <n-data-table
        v-if="boundConditions.length && !listLoading"
        :columns="columns"
        :data="boundConditions"
        :bordered="false"
      />
      <div
        v-else-if="listLoading"
        class="flex h-full justify-center"
      >
        <n-spin />
      </div>
      <div
        v-else
        class="flex h-full justify-center items-center font-semibold text-primary"
      >
        {{ t('components.boundConditions.info.noneActive') }}
      </div>
    </n-scrollbar>
    <template #action>
      <div class="flex justify-evenly">
        <n-button
          type="primary"
          @click="handleAdd"
        >
          <template #icon>
            <n-icon
              size="16"
              color="#fff"
            >
              <AddIcon />
            </n-icon>
          </template>
          {{ t('components.boundConditions.button.add') }}
        </n-button>
        <n-button
          type="primary"
          @click="handleAssign"
        >
          <template #icon>
            <n-icon color="#fff">
              <StartIcon />
            </n-icon>
          </template>
          {{ t('components.boundConditions.button.startCalculation') }}
        </n-button>
      </div>
    </template>
  </n-card>
</template>

<script lang="ts" setup>
import {KeyboardDoubleArrowRightSharp as StartIcon} from '@vicons/material'
import {Add16Filled as AddIcon} from '@vicons/fluent'
import type {PropType} from 'vue'
import {computed, h, onMounted, ref, watch} from 'vue'

import {useDialog} from 'naive-ui'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import AddConstraint from '@/components/bound-conditions/AddBoundCondition.vue'
import type {BoundCondition} from '@/api/modules/boundConditions'
import api from '@/api'
import useBoundConditions from '@/utils/composables/useBoundConditions'

const {t} = useI18n()

const props = defineProps({
  fetchAssignments: {
    type: Function as PropType<() => void>,
    required: true,
  },
})

const handleAssign = () => {
  props.fetchAssignments()
}

const store = useStore()

const listLoading = computed(() => store.getters['BoundConditions/boundConditionsLoading'])

const boundConditions = computed(() => store.getters['BoundConditions/getBoundConditions'])

const fetchList = () => {
  store.dispatch('BoundConditions/SET_BOUND_CONDITION_LIST')
}

const handleDelete = (id: BoundCondition['id']) => {
  store.dispatch('BoundConditions/DELETE_BOUND_CONDITION', id)
  api.boundConditions.deleteBoundCondition(id).then((res) => {
    fetchList()
    return res
  })
}

const {getColumns} = useBoundConditions()

const columns = getColumns(handleDelete)

const dialog = useDialog()

const addLoading = ref(false)

const handleAdd = () => {
  const add = (boundCondition: BoundCondition) => {
    addLoading.value = true
    api.boundConditions.createBoundCondition(boundCondition).then((res) => {
      fetchList()
      return res
    }).finally(() => {
      addLoading.value = false
      d.destroy()
    })
  }

  const d = dialog.info({
    title: 'Constraint hinzufügen',
    content: () => {
      // @ts-expect-error TODO
      return h(AddConstraint, {
        addFunction: add,
        loadingValue: addLoading.value,
      })
    },
  })

  return d
}

watch(() => store.getters['Auth/isAuth'], (value) => {
  if (value)
    fetchList()
})

onMounted(() => {
  if (store.getters['Auth/isAuth'])
    fetchList()
})
</script>

<style scoped>

</style>
