import type {GetterTree} from 'vuex'
import type {RootState} from '@/store'
import type {CompanyState} from '@/store/modules/companies/index'
import type {Company} from '@/api/modules/companies'

const getters: GetterTree<CompanyState, RootState> = {
  getCompanyList: (state): Array<Company> => state.data,
  getCompanyName: state => (id: Company['id']): string => state.data.find(company => company.id === id)?.name ?? '',
  companiesLoading: (state): boolean => state.loading,
}

export default getters
