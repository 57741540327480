import type {Module} from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import type {RootState} from '@/store'
import type {BoundCondition} from '@/api/modules/boundConditions'

export interface BoundConditionState {
  data: Array<BoundCondition>
  loading: boolean
}

export const defaultState = (): BoundConditionState => {
  return {
    data: [],
    loading: false,
  }
}

const namespaced = true

const BoundConditions: Module<BoundConditionState, RootState> = {
  namespaced,
  state: defaultState(),
  getters,
  actions,
  mutations,
}

export default BoundConditions
