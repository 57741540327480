import type {Module} from 'vuex'
import type {AssignmentResult} from '@/api/modules/assignments'
import type {RootState} from '@/store'
import actions from '@/store/modules/assignments/actions'
import getters from '@/store/modules/assignments/getters'
import mutations from '@/store/modules/assignments/mutations'

export interface AssignmentState {
  standardAssign: {
    data: AssignmentResult
    error: string
    loading: boolean
  }
  constraintAssign: {
    data: AssignmentResult
    error: string
    loading: boolean
  }
}

export const defaultState = (): AssignmentState => {
  return {
    standardAssign: {
      data: {
        assignments: [],
        utilization: [],
        totalCosts: 0,
      },
      error: '',
      loading: false,
    },
    constraintAssign: {
      data: {
        assignments: [],
        utilization: [],
        totalCosts: 0,
      },
      error: '',
      loading: false,
    },
  }
}

const namespaced = true

const Assignments: Module<AssignmentState, RootState> = {
  namespaced,
  state: defaultState(),
  actions,
  getters,
  mutations,
}

export default Assignments
