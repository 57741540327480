import type {ActionTree} from 'vuex'
import type {AuthState} from './index'
import api from '@/api'
import type {RootState} from '@/store'
import type {AuthCredentials} from '@/api/modules/auth'

const actions: ActionTree<AuthState, RootState> = {
  LOGIN: () => {
    return api.auth.login().then(({data}) => {
      // commit('SET_TOKEN', data[0].idToken);
      return data
    })
  },
  SET_TOKEN: ({commit}, authCredentials: AuthCredentials) => {
    commit('SET_TOKEN', authCredentials)
  },
}

export default actions
