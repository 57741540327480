import type {DataTableColumn} from 'naive-ui'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import type {Assignment} from '@/api/modules/assignments'

type Row = Assignment

export default () => {
  const store = useStore()

  const {t} = useI18n()

  const getColumns: () => Array<DataTableColumn<Row>> = () => {
    return [
      {
        title: t('components.areaAssignment.table.task'),
        key: 'area',
        width: 175,
        render: (assignment: Assignment) => {
          return store.getters['Areas/getAreaName'](assignment.area)
        },
        ellipsis: {
          tooltip: true,
        },
      },
      {
        title: t('components.areaAssignment.table.company'),
        key: 'company',
        width: 175,
        render: (assignment: Assignment) => {
          return store.getters['Companies/getCompanyName'](assignment.company)
        },
        ellipsis: {
          tooltip: true,
        },
      },
      {
        title: t('components.areaAssignment.table.price'),
        key: 'price',
        width: 175,
        render: (assignment: Assignment) => {
          return assignment.price.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0,
          })
        },
        ellipsis: {
          tooltip: true,
        },
      },
    ]
  }

  return {
    getColumns,
  }
}
