import type {AxiosPromise} from 'axios'
import axiosWithAuth from '@/api/axios'
import type {Company} from '@/api/modules/companies'
import type {BoundCondition} from '@/api/modules/boundConditions'

export interface Area {
  id: string // UUID
  name: string
  ratioAsphalt: number
  ratioPavement: number
  ratioGreen: number
  size: number
  country: string
  assignedTo?: Company['id']
  rolloutStartDate: string
  areaConstraint: BoundCondition['id']
}

const areas = {
  fetchAreas: (): AxiosPromise<Array<Area>> => {
    return axiosWithAuth.get('/areas/')
  },
}

export default areas
