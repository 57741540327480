import type {AxiosPromise} from 'axios'
import axiosWithAuth from '@/api/axios'

export interface Capacity {
  year: number
  capacity: number
  workload: number
}

export interface Company {
  id: string // UUID
  name: string
  chargeAsphalt: number
  chargePavement: number
  chargeGreen: number
  chargePlanningDocu: number
  chargeMaterial: number
  country: string
  capacity: Array<Capacity>
}

const companies = {
  fetchCompanies: (): AxiosPromise<Array<Company>> => {
    return axiosWithAuth.get('/companies/')
  },
}

export default companies
