import type {MutationTree} from 'vuex'
import type {AuthState} from './index'
import type {AuthCredentials} from '@/api/modules/auth'

const mutations: MutationTree<AuthState> = {
  SET_TOKEN: (state, payload: AuthCredentials) => {
    state.data.token = payload.idToken
    state.data.expiresOn = payload.expiresOn
  },
}

export default mutations
