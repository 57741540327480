<template>
  <n-dropdown
    placement="bottom-start"
    trigger="click"
    size="small"
    :options="options"
    @select="handleSelect"
    @clickoutside="toggleDropdown"
  >
    <div
      class="flex text-white mt-1"
      @click="toggleDropdown"
    >
      <n-icon
        size="30"
      >
        <DropDownIconInactive v-if="!dropdown" />
        <DropDownIconActive v-else />
      </n-icon>
      <div class="m-auto">
        {{ model }}
      </div>
    </div>
  </n-dropdown>
</template>
<script lang="ts" setup>
import {ArrowDropDownOutlined as DropDownIconActive, ArrowRightOutlined as DropDownIconInactive} from '@vicons/material'

import type {PropType} from 'vue'
import {computed, onMounted, ref} from 'vue'
import {useStore} from 'vuex'

const props = defineProps({
  type: {
    type: String as PropType<'year' | 'country'>,
    default: 'year',
    required: false,
  },
})

const dropdown = ref(false)

const toggleDropdown = () => {
  dropdown.value = !dropdown.value
}

const options = computed(() => {
  return props.type === 'year'
    ? [{
        label: '2022',
        key: '2022',
      }, {
        label: '2023',
        key: '2023',
      }, {
        label: '2024',
        key: '2024',
      }]
    : [{
        label: 'DE',
        key: 'de',
      }, {
        label: 'AT',
        key: 'at',
      }]
})

const model = ref(props.type === 'year' ? '2023' : 'DE') // todo: set current year

const store = useStore()

const refreshApi = () => {
  store.dispatch('Areas/ADD_AREA_LIST')
  store.dispatch('Companies/ADD_COMPANY_LIST')
  store.dispatch('BoundConditions/SET_BOUND_CONDITION_LIST')
  store.dispatch('Assignments/RESET_ASSIGNMENTS')
}

const handleSelect = ref((key: string) => {
  toggleDropdown()
  model.value = options.value.find(option => option.key === key)?.label ?? '?'
  props.type === 'year' ? store.dispatch('Settings/SET_YEAR', model.value) : store.dispatch('Settings/SET_COUNTRY', model.value)
  refreshApi()
})

onMounted(() => {
  props.type === 'year' ? store.dispatch('Settings/SET_YEAR', model.value) : store.dispatch('Settings/SET_COUNTRY', model.value)
})

</script>
<style scoped>
</style>
