import test from './modules/test'
import areas from '@/api/modules/areas'
import assignments from '@/api/modules/assignments'
import auth from '@/api/modules/auth'
import boundConditions from '@/api/modules/boundConditions'
import companies from '@/api/modules/companies'
import updates from '@/api/modules/updates'

const api = {
  areas,
  assignments,
  auth,
  boundConditions,
  companies,
  test,
  updates,
}

export default api
