import type {GetterTree} from 'vuex'
import type {RootState} from '@/store'
import type {AssignmentState} from '@/store/modules/assignments/index'

const getters: GetterTree<AssignmentState, RootState> = {
  getStandardAssignment: state => state.standardAssign.data.assignments,
  getStandardUtilization: state => state.standardAssign.data.utilization,
  getStandardTotalCosts: state => state.standardAssign.data.totalCosts,
  getConstraintAssignment: state => state.constraintAssign.data.assignments,
  getConstraintUtilization: state => state.constraintAssign.data.utilization,
  getConstraintTotalCosts: state => state.constraintAssign.data.totalCosts,
  standardAssignmentLoading: state => state.standardAssign.loading,
  standardAssignmentError: state => state.standardAssign.error,
  constraintAssignmentLoading: state => state.constraintAssign.loading,
  constraintAssignmentError: state => state.constraintAssign.error,
}

export default getters
