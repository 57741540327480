import type {Module} from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import type {RootState} from '@/store'
import type {Area} from '@/api/modules/areas'

export interface AreaState {
  data: Array<Area>
  loading: boolean
}

export const defaultState = (): AreaState => {
  return {
    data: [],
    loading: false,
  }
}

const namespaced = true

const Areas: Module<AreaState, RootState> = {
  namespaced,
  state: defaultState(),
  getters,
  actions,
  mutations,
}

export default Areas
