import type {ActionTree} from 'vuex'
import type {RootState} from '@/store'
import type {CompanyState} from '@/store/modules/companies/index'
import api from '@/api'

const actions: ActionTree<CompanyState, RootState> = {
  ADD_COMPANY_LIST: ({commit}) => {
    commit('SET_LOADING', true)
    api.companies.fetchCompanies().then((res) => {
      commit('ADD_COMPANY_LIST', res.data)
    }).finally(() => {
      commit('SET_LOADING', false)
    })
  },
}

export default actions
