<template>
  <div
    ref="resizeRef"
    class="flex flex-col"
  >
    <div
      v-if="!props.hideLegend"
      ref="legendRef"
      class="legend flex mb-2"
      :class="props.legendColor"
    >
      <slot name="label-y" />
      <div class="mx-auto">
        <slot name="label-center" />
      </div>
      <div class="flex">
        <div
          v-for="label in subgroupKeys"
          :key="label"
          class="flex"
        >
          <rect class="legend-rect" />
          <div :class="props.legendLabelColor">
            {{ label }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <div>
        <svg
          ref="yScaleRef"
          class="h-full"
          :style="{width: yScaleWidth + 'px'}"
        />
      </div>
      <n-scrollbar
        ref="scrollbar"
        x-scrollable
        trigger="none"
      >
        <svg
          ref="chartRef"
          :style="{width: chartWidth + 'px', height: height + 'px'}"
        />
      </n-scrollbar>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, ref, watchEffect} from 'vue'
import {
  axisBottom, axisLeft, max, scaleBand, scaleLinear, scaleOrdinal, select, sum,
} from 'd3'
import useResizeObserver from '../../utils/composables/useResizeObserver'
import groupedBars from './groupedBars'
import stackedBars from './stackedBars'
import type {Item} from './interface'
import {barchartProps} from './interface'

// TODO: types
/* eslint-disable @typescript-eslint/no-explicit-any */

const props = defineProps(barchartProps)

const {
  resizeRef, resizeState,
} = useResizeObserver()
const {drawGroupedBars} = groupedBars()
const {drawStackedBars} = stackedBars()

const chartRef = ref(null)
const yScaleRef = ref(null)
const legendRef = ref(null)
const margin = {
  left: 5, top: 5, right: 0, bottom: 30,
} // bottom includes scrollbar
const width = ref(0)
const height = ref(0)
const chartHeight = ref(0)
const chartWidth = ref(0)
const groupWidth = ref(0)
const yScaleWidth = ref(0)
const yTicksRatio = 5 // steps: 1-,2-,5-multiples of powers of ten
const subgroupKeys = ref<Array<string>>([]) // labels for bar partition or bars within groups (e.g. micro, macro, stoppage)
const xKeys = ref<Array<string>>([]) // x-labels (e.g. Kartonierer, TZM)
const barWidthArray = ref<Array<number>>([])
const gridColor1 = '#a8b9c3'
const gridColor2 = '#cfd7dc'

const initData = () => {
  xKeys.value = props.data.map((d: any) => {
    return d.label // TODO: translation
  })
  subgroupKeys.value = Object.keys(props.data[0].values)
  barWidthArray.value = props.barWidth
}

const calculateChartDimensions = () => {
  width.value = resizeState.dimensions.width
  height.value = resizeState.dimensions.height
  chartHeight.value = height.value - margin.top - margin.bottom
  // if input array for barWidth has not enough values fill up cyclically
  if (props.barWidth.length < subgroupKeys.value.length) {
    const i = 0
    for (let j = props.barWidth.length; j < subgroupKeys.value.length; j++)
      barWidthArray.value.push(props.barWidth[i])
  }
  groupWidth.value = props.type === 'stacked' ? max(props.barWidth) as number : sum(barWidthArray.value) + props.barPaddingInner * (subgroupKeys.value.length - 1)
  const realWidth = (groupWidth.value + props.barPaddingOuter) * props.data.length
  chartWidth.value = realWidth < width.value ? width.value - yScaleWidth.value : realWidth
}

const initXAxis = (): any => {
  return scaleBand()
    .domain(xKeys.value)
    .range([0, chartWidth.value - margin.left])
    .padding(0.1)
}

const yDomain = (data: any): number => {
  const maxVal = max(data, (item: Item): number => {
    return (props.type === 'stacked') ? +sum(Object.values(item.values)) : max(Object.values(item.values)) as number
  }) as number

  if (props.yMin > maxVal) {
    return props.yMin
  }
  else {
    // increase domain to always end on a tick
    return maxVal + maxVal / 10
  }
}

const initYAxis = (data: any): any => {
  const scale = scaleLinear()
    .domain([0, yDomain(data)])
    .range([chartHeight.value, 10]).nice() // additional padding for tooltip

  // update width of yScale
  const lastTickWidth = (`${scale.ticks()[scale.ticks().length - 1]}`).length * 10
  yScaleWidth.value = max([15, lastTickWidth]) as number

  return scale
}

const initChart = (data: any): any => {
  calculateChartDimensions()
  const svg = select(chartRef.value)
    .append('g')
    .attr('transform',
          `translate(${margin.left},${margin.top})`)

  const xScale = initXAxis()
  svg.append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0,${chartHeight.value})`)
    .call(axisBottom(xScale).tickSize(0))

  const yScale = initYAxis(data)
  const ySvg = select(yScaleRef.value)
    .append('g').attr('class', 'y axis')
    .call(axisLeft(yScale).tickSize(0).ticks(yTicksRatio))
    .attr('height', chartHeight.value + margin.top + margin.bottom)
    .attr('transform',
          `translate(${yScaleWidth.value},${margin.top})`)
  ySvg.select('.domain').remove()

  // add second y-Scale, remove labels & axis, use ticks as grid, style ticks
  svg.append('g')
    .attr('class', 'x grid')
    .call(axisLeft(yScale).tickSize(-chartWidth.value).tickFormat(() => '').ticks(yTicksRatio))
    .call((g: any) => g.selectAll('.tick:not(:first-of-type) line').attr('stroke', gridColor2))
    .call((g: any) => g.select('.tick line').attr('stroke', gridColor1))
  svg.selectAll('.domain').remove()

  svg.append('g').attr('class', 'data')

  svg.selectAll('g.data').append('g').attr('class', 'tooltip')

  return [svg, ySvg]
}

const createChart = (data: any, svg: any, ySvg: any) => {
  // update x-Axis
  const xScale = initXAxis()
  svg.selectAll('g.x.axis')
    .attr('transform', `translate(0,${chartHeight.value})`)
    .call(axisBottom(xScale).tickSize(0))

  // update y-Axis
  const yScale = initYAxis(data)
  ySvg.attr('height', chartHeight.value + margin.top + margin.bottom)
    .attr('transform',
          `translate(${yScaleWidth.value},${margin.top})`)
    .call(axisLeft(yScale).tickSize(0).ticks(yTicksRatio))
  ySvg.select('.domain').remove()

  // update grid
  svg.selectAll('g.x.grid')
    .call(axisLeft(yScale).tickSize(-chartWidth.value).tickFormat(() => '').ticks(yTicksRatio))
    .call((g: any) => g.selectAll('.tick:not(:first-of-type) line').attr('stroke', gridColor2))
    .call((g: any) => g.select('.tick line').attr('stroke', gridColor1))
  svg.selectAll('.domain').remove()

  const colorRange = () => {
    if (props.barColors[0] === '') {
      // default colors
      return props.type === 'grouped' ? ['#00528c', '#00bbff'] : ['#fbbdcb', '#ff3162', '#96002e']
    }
    else {
      return props.barColors
    }
  }
  const colorScale = scaleOrdinal()
    .domain(subgroupKeys.value)
    .range(colorRange())

  if (props.type === 'grouped')
    drawGroupedBars(data, svg, colorScale, xScale, yScale, barWidthArray.value, props.barPaddingInner, groupWidth.value, chartWidth.value, chartHeight.value, subgroupKeys.value, props.tooltipColor, props.tooltipFormat)

  if (props.type === 'stacked')
    drawStackedBars(data, svg, colorScale, xScale, yScale, props.barWidth[0], props.barPaddingInner, groupWidth.value, chartWidth.value, chartHeight.value, subgroupKeys.value, props.tooltipColor, props.tooltipFormat)

  if (!props.hideLegend) {
    select(legendRef.value).selectAll('.legend-rect')
      .data(subgroupKeys.value)
      .style('background-color', (d: string): string => {
        return colorScale(d) as string
      })
  }
}

onMounted(() => {
  initData()
  const svg = initChart(props.data)
  watchEffect(() => {
    initData()
    calculateChartDimensions()
    if (height.value && width.value)
      createChart(props.data, svg[0], svg[1])
  })
})

</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

.legend {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.legend-rect {
  @apply w-2.5;
  @apply h-2.5;
  @apply my-auto;
  @apply ml-2;
  @apply mr-1;
}

:deep(.y.axis) {
  @apply text-gray-600;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

:deep(.x.axis) {
  @apply text-gray-600;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

:deep(.tooltip-text) {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

:deep(.tooltip-box) {
  display: flex;
  flex-direction: column;
  align-items: center;
  @apply rounded-sm;
  @apply drop-shadow;
}
</style>
