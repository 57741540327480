import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import store from '@/store'

const options = {
  preservedKeys: ['rollout_start_date__year'],
}

const axiosWithAuth = applyCaseMiddleware(axios.create({
  timeout: 28000,
  baseURL: `${import.meta.env.VITE_APP_BACKEND_URL}/api` || '/api',
}), options)

axiosWithAuth.interceptors.request.use(
  (config) => {
    const token = store.getters['Auth/getToken']
    if (token && config?.headers)
      // eslint-disable-next-line
      config.headers['Authorization'] = `Bearer ${token}`

    // create config.params if it has not been set
    config.params = config.params || {}
    // add year + country-params to all requests
    config.params.rollout_start_date__year = store.getters['Settings/getYear']
    config.params.country = store.getters['Settings/getCountry']

    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

export default axiosWithAuth
