import type {MutationTree} from 'vuex'
import type {AssignmentState} from '@/store/modules/assignments/index'
import {defaultState} from '@/store/modules/assignments/index'
import type {AssignmentResult} from '@/api/modules/assignments'

const mutations: MutationTree<AssignmentState> = {
  SET_STANDARD_LOADING: (state, payload: boolean) => {
    state.standardAssign.loading = payload
  },
  SET_STANDARD_ERROR: (state, payload: string) => {
    state.standardAssign.error = payload
  },
  SET_STANDARD_ASSIGNMENTS: (state, payload: AssignmentResult) => {
    state.standardAssign.data = payload
  },
  SET_CONSTRAINT_LOADING: (state, payload: boolean) => {
    state.constraintAssign.loading = payload
  },
  SET_CONSTRAINT_ERROR: (state, payload: string) => {
    state.constraintAssign.error = payload
  },
  SET_CONSTRAINT_ASSIGNMENTS: (state, payload: AssignmentResult) => {
    state.constraintAssign.data = payload
  },
  RESET_ASSIGNMENTS: (state) => {
    Object.assign(state, defaultState())
  },
}

export default mutations
