import type {AxiosPromise} from 'axios'
import axiosWithAuth from '@/api/axios'

export interface UpdateResult {
  message: string
}

const updates = {
  triggerAreaMasterUpdate: (): AxiosPromise<UpdateResult> => {
    return axiosWithAuth.get('/update-from-areamaster/')
  },
}

export default updates
