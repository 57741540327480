import type {MutationTree} from 'vuex'
import type {SettingsState} from '@/store/modules/settings/index'

const mutations: MutationTree<SettingsState> = {
  SET_COUNTRY: (state, payload: SettingsState['country']) => {
    state.country = payload
  },
  SET_YEAR: (state, payload: SettingsState['year']) => {
    state.year = payload
  },
  SET_GEO_RESTRICTION: (state, payload: SettingsState['geoRestrict']) => {
    state.geoRestrict = payload
  },
}

export default mutations
