<template>
  <div
    class="flex"
    :class="highlight ? 'bg-primary rounded' : ''"
  >
    <n-card
      class="m-1"
      :title="title"
      header-style="margin: auto"
      :segmented="{ content: true, footer: 'soft' }"
    >
      <div
        v-if="error && !loading"
        class="flex h-full justify-center items-center font-semibold text-primary"
      >
        {{ error }}
      </div>
      <div
        v-else-if="loading"
        class="flex h-full justify-center items-center w-full"
      >
        <n-spin />
      </div>
      <n-data-table
        v-else
        :columns="columns"
        :data="data"
        :bordered="false"
        :max-height="220"
      >
        <template #empty>
          <div class="flex h-full justify-center items-center font-semibold text-primary">
            {{ t('common.info.noData') }}
          </div>
        </template>
      </n-data-table>
    </n-card>
  </div>
</template>

<script lang="ts" setup>
import type {PropType} from 'vue'
import {computed, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import type {Assignment} from '@/api/modules/assignments'
import useAreaAssignment from '@/utils/composables/useAreaAssignment'

const {t} = useI18n()

const props = defineProps({
  data: {
    type: Array as PropType<Array<Assignment>>,
    default: () => [],
    required: true,
  },
  withConstraints: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  highlight: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const title = ref(props.withConstraints ? t('components.areaAssignment.titleConstraints') : t('components.areaAssignment.title'))

const {getColumns} = useAreaAssignment()

const columns = getColumns()

const store = useStore()

// todo: i18n -> german
const error = computed(() => props.withConstraints ? store.getters['Assignments/constraintAssignmentError'] : store.getters['Assignments/standardAssignmentError'])
</script>

<style scoped>

</style>
