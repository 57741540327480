import type {AxiosPromise} from 'axios'
import axiosWithAuth from '@/api/axios'
import type {Area} from '@/api/modules/areas'
import type {Company} from '@/api/modules/companies'

export enum BoundConditionType {
  must_have_area = 'Feste Zuweisung',
  cannot_have_area = 'Verbotene Zuweisung',
  minimum_meters = 'min. Strecke',
  minimum_areas = 'min. Gebiete',
  max_surface_ratio = 'max. Oberflächenquote',
}

export enum SurfaceType {
  ratio_asphalt = 'Asphalt',
  ratio_pavement = 'Befestigt',
  ratio_green = 'Unbefestigt',
}

export interface BoundCondition {
  id: number
  type: 'must_have_area' | 'cannot_have_area' | 'minimum_meters' | 'minimum_areas' | 'max_surface_ratio' | 'ignore_area' | 'include'
  description: string
  user?: number
  area?: Area['id']
  company?: Company['id']
  surfaceType?: 'ratio_asphalt' | 'ratio_pavement' | 'ratio_green'
  ratio?: number
  minimumM?: number
  minimumArea?: number
}

// remove id, description, user from NewBoundCondition, make type optional
export type NewBoundCondition = Omit<BoundCondition, 'id' | 'description' | 'user' | 'type'> & Partial<Pick<BoundCondition, 'type'>>

const boundConditions = {
  fetchBoundConditions: (): AxiosPromise<Array<BoundCondition>> => {
    return axiosWithAuth.get('/bound-conditions/')
  },
  createBoundCondition: (boundCondition: NewBoundCondition): AxiosPromise<BoundCondition> => {
    let ratio: number | undefined
    if (boundCondition.type === 'max_surface_ratio')
      ratio = boundCondition.ratio ? boundCondition.ratio / 100 : undefined
    return axiosWithAuth.post('/bound-conditions/', {...boundCondition, ratio})
  },
  deleteBoundCondition: (id: BoundCondition['id']) => {
    return axiosWithAuth.delete(`/bound-conditions/${id}/`)
  },
}

export default boundConditions
