<template>
  <div class="flex flex-col gap-2">
    <div>Constrainttyp:</div>
    <n-select
      v-model:value="modelValue.type"
      :options="typeOptions"
      placeholder="Bitte auswählen"
      :fallback-option="false"
      :disabled="!!modelValue.company || !!modelValue.area || !!modelValue.minimumM || !!modelValue.minimumArea"
    />

    <div v-show="modelValue.type">
      <div>Gewählter Tiefbauer:</div>
      <n-select
        v-model:value="modelValue.company"
        :options="companyOptions"
        :fallback-option="false"
        placeholder="Bitte auswählen"
      />
    </div>

    <div v-show="modelValue.type === 'must_have_area' || modelValue.type === 'cannot_have_area'">
      <div>Gewähltes Gebiet:</div>
      <n-select
        v-model:value="modelValue.area"
        :options="areaOptions"
        :fallback-option="false"
        placeholder="Bitte auswählen"
      />
    </div>
    <div v-show="modelValue.type === 'minimum_meters'">
      <div>Mindestabnahmestrecke:</div>
      <n-input-number
        v-model:value="modelValue.minimumM"
        :min="1"
        :show-button="false"
        placeholder="Bitte eingeben"
      />
    </div>
    <div v-show="modelValue.type === 'minimum_areas'">
      <div>Anzahl Gebiete:</div>
      <n-input-number
        v-model:value="modelValue.minimumArea"
        :min="1"
        :show-button="false"
        placeholder="Bitte eingeben"
      />
    </div>
    <div v-show="modelValue.type === 'max_surface_ratio'">
      <div>Oberflächentyp:</div>
      <n-select
        v-model:value="modelValue.surfaceType"
        :options="surfaceOptions"
        :fallback-option="false"
        placeholder="Bitte eingeben"
      />
    </div>
    <div v-show="modelValue.type === 'max_surface_ratio'">
      <div>Anteil in %:</div>
      <n-input-number
        v-model:value="modelValue.ratio"
        :min="1"
        :max="100"
        :show-button="false"
        placeholder="Bitte eingeben"
      />
    </div>

    <div class="flex justify-end">
      <n-button
        type="info"
        :disabled="!(modelValue.area && modelValue.company)
          && !(modelValue.company && modelValue.minimumM)
          && !(modelValue.company && modelValue.minimumArea)
          && !(modelValue.company && modelValue.surfaceType && modelValue.ratio)"
        :loading="loadingValue"
        @click="props.addFunction?.(modelValue)"
      >
        {{ t('common.action.add') }}
      </n-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {PropType} from 'vue'
import {ref} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import type {NewBoundCondition} from '@/api/modules/boundConditions'
import {SurfaceType} from '@/api/modules/boundConditions'

const {t} = useI18n()

const props = defineProps({
  addFunction: {
    type: Function as PropType<(boundCondition: NewBoundCondition) => void>,
    required: true,
  },
  loadingValue: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
})

const modelValue = ref<NewBoundCondition>({})

const typeOptions = ref([{
  label: t('components.boundConditions.addDialog.mustHaveArea'),
  value: 'must_have_area',
},
{
  label: t('components.boundConditions.addDialog.cannotHaveArea'),
  value: 'cannot_have_area',
},
{
  label: t('components.boundConditions.addDialog.minimumMeters'),
  value: 'minimum_meters',
},
{
  label: t('components.boundConditions.addDialog.minimumAreas'),
  value: 'minimum_areas',
},
{
  label: t('components.boundConditions.addDialog.maxSurfaceRatio'),
  value: 'max_surface_ratio',
}])

const store = useStore()

const getAreaOptions = () => {
  const options: {label: string; value: number}[] = []
  store.getters['Areas/getAreaList'].forEach((area) => {
    options.push({label: area.name, value: area.id})
  })
  return options
}

const areaOptions = ref(getAreaOptions())

const getCompanyOptions = () => {
  const options: {label: string; value: number}[] = []
  store.getters['Companies/getCompanyList'].forEach((company) => {
    options.push({label: company.name, value: company.id})
  })
  return options
}

const companyOptions = ref(getCompanyOptions())

const getSurfaceOptions = () => {
  const options: {label: string; value: string}[] = []
  Object.keys(SurfaceType).forEach((key) => {
    options.push({label: SurfaceType[key], value: key})
  })
  return options
}

const surfaceOptions = ref(getSurfaceOptions())
</script>
