import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

export interface AuthCredentials {
  idToken: string
  expiresOn: string
  userId: string
}

const axiosAuth = applyCaseMiddleware(axios.create({
  timeout: 28000,
  baseURL: 'https://dev.rcontrol.fiberoptics.cloud',
}))

const auth = {
  login: () => {
    return axiosAuth.get<Array<AuthCredentials>>('/.auth/me')
  },
  refresh: () => {
    return axiosAuth.get('/.auth/refresh')
  },
}

export default auth
