import {createI18n} from 'vue-i18n'
import deDE from '@/locales/de-DE.json'

export const i18n = createI18n({
  legacy: false,
  locale: 'deDE',
  fallbackLocale: 'deDE',
  messages: {
    deDE,
  },
})
