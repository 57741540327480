import type {GetterTree} from 'vuex'
import type {AuthState} from './index'
import type {RootState} from '@/store'

const getters: GetterTree<AuthState, RootState> = {
  getToken: (state): string => state.data.token,
  expiresOn: (state): string => state.data.expiresOn,
  isAuth: (state): boolean => !!state.data.token || (import.meta.env.MODE === 'development'),
}

export default getters
