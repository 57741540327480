import type {Module} from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import type {RootState} from '@/store'

export interface AuthState {
  data: {
    token: string
    expiresOn: string
  }
}

export const defaultState = (): AuthState => {
  return {
    data: {
      token: '',
      expiresOn: '',
    },
  }
}

const namespaced = true

const Auth: Module<AuthState, RootState> = {
  namespaced,
  state: defaultState(),
  getters,
  actions,
  mutations,
}

export default Auth
