import type {MutationTree} from 'vuex'
import type {Area} from '@/api/modules/areas'
import type {AreaState} from '@/store/modules/areas/index'

const mutations: MutationTree<AreaState> = {
  ADD_AREA_LIST: (state, payload: Array<Area>) => {
    state.data = payload
  },
  SET_LOADING: (state, payload: boolean) => {
    state.loading = payload
  },
}

export default mutations
