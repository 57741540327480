<template>
  <n-layout>
    <layout-header />
    <n-layout-content>
      <div class="grid grid-cols-24 gap-5 m-5">
        <div class="col-span-8 flex flex-col m-1">
          <bound-conditions-list
            class="h-96"
            :fetch-assignments="fetchAssignments"
          />
          <div class="flex flex-row">
            <AreaCard
              :fetch-list="fetchAreas"
              :loading="areasLoading"
            />
            <CompanyCard
              :fetch-list="fetchCompanies"
              :loading="companiesLoading"
            />
          </div>
        </div>
        <div class="col-span-16">
          <div class="flex flex-col">
            <div class="flex flex-row gap-5 mb-5">
              <div class="w-1/2">
                <area-assingment
                  class="h-96"
                  :data="assignData"
                  :loading="assignLoading"
                />
              </div>
              <div class="w-1/2">
                <area-assingment
                  class="h-96"
                  :with-constraints="true"
                  :data="assignDataConstraints"
                  :loading="assignConstraintsLoading"
                  highlight
                />
              </div>
            </div>
            <div class="flex justify-center text-primary text-xl">
              <div v-if="totalCosts">
                {{
                  totalCosts.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 2
                  })
                }}
              </div>
              <div
                v-if="totalCosts && totalCostsConstraints"
                class="flex mx-40"
              >
                <n-icon
                  v-if="calculateCostDifference(totalCosts, totalCostsConstraints) < 0"
                  size="30"
                  color="#CE332E"
                >
                  <ArrowUp />
                </n-icon>
                <n-icon
                  v-if="calculateCostDifference(totalCosts, totalCostsConstraints) > 0"
                  size="30"
                  color="#00CFB4"
                >
                  <ArrowDown />
                </n-icon>
                <div class="mx-2 my-auto">
                  {{
                    (calculateCostDifference(totalCosts, totalCostsConstraints) < 0) ?
                      '+' : (calculateCostDifference(totalCosts, totalCostsConstraints) > 0) ? '-' : '+/-'
                  }}
                  {{ calculateCostDifferenceString(totalCosts, totalCostsConstraints) }}
                </div>
                <n-icon
                  v-if="calculateCostDifference(totalCosts, totalCostsConstraints) < 0"
                  size="30"
                  color="#CE332E"
                >
                  <ArrowUp />
                </n-icon>
                <n-icon
                  v-if="calculateCostDifference(totalCosts, totalCostsConstraints) > 0"
                  size="30"
                  color="#00CFB4"
                >
                  <ArrowDown />
                </n-icon>
              </div>
              <div v-if="totalCostsConstraints">
                {{
                  totalCostsConstraints.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 2
                  })
                }}
              </div>
              <div
                v-else
                class="w-1/2"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-5 m-6">
        <n-card
          class="w-full"
          :title="t('components.utilizationChart.title')"
          header-style="margin: auto"
          :segmented="{ content: true, footer: 'soft' }"
        >
          <div
            v-if="assignError && !assignConstraintsLoading"
            class="flex justify-center items-center h-56 font-semibold text-primary"
          >
            {{ assignError }}
          </div>
          <chart-bar
            v-else-if="utilizationChartData.length > 0"
            class="h-56"
            :data="utilizationChartData"
            :y-min="100"
            :bar-colors="['#E74E0D', '#ffdcc7']"
            :bar-width="[38]"
            :bar-padding-outer="100"
            :tooltip-color="'#E74E0D'"
            :tooltip-format="val => {return val + ' %'}"
            hide-legend
          />
          <div
            v-else
            class="flex justify-center items-center h-56 font-semibold text-primary"
          >
            {{ t('common.info.noData') }}
          </div>
        </n-card>
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue'
import {ArrowDown, ArrowUp} from '@vicons/ionicons5'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import LayoutHeader from '@/layout/LayoutHeader.vue'
import type {Utilization} from '@/api/modules/assignments'
import ChartBar from '@/components/chart-bar/ChartBar.vue'
import type {Item} from '@/components/chart-bar/interface'
import BoundConditionsList from '@/components/bound-conditions/BoundConditionsList.vue'
import AreaAssingment from '@/components/assingments/AreaAssingment.vue'
import AreaCard from '@/components/areas/AreaCard.vue'
import CompanyCard from '@/components/companies/CompanyCard.vue'

const {t} = useI18n()

const calculateCostDifference = ref((value1: number, value2: number) => {
  return value1 - value2
})

const calculateCostDifferenceString = ref((value1: number, value2: number) => {
  return `${(Math.abs((value1 - value2) / ((value1 + value2) / 2)) * 100).toFixed()} %`
})

const store = useStore()

const assignData = computed(() => store.getters['Assignments/getStandardAssignment'])
const assignDataConstraints = computed(() => store.getters['Assignments/getConstraintAssignment'])
const totalCosts = computed(() => store.getters['Assignments/getStandardTotalCosts'])
const totalCostsConstraints = computed(() => store.getters['Assignments/getConstraintTotalCosts'])
const assignError = computed(() => store.getters['Assignments/constraintAssignmentError'])

const mapChartData = (utilData: Array<Utilization>): Array<Item> => {
  const utilItemList: Item[] = []
  utilData.forEach((utilItem) => {
    utilItemList.push({
      label: `${store.getters['Companies/getCompanyName'](utilItem.company)}`,
      tooltip: +(utilItem.percent * 100).toFixed(0),
      values: {
        soll: utilItem.percent * 100,
        ist: 100 - (utilItem.percent * 100),
      },
    })
  })
  return utilItemList
}

const utilizationChartData = computed(() => mapChartData(store.getters['Assignments/getConstraintUtilization']))

const assignLoading = computed(() => store.getters['Assignments/standardAssignmentLoading'])
const assignConstraintsLoading = computed(() => store.getters['Assignments/constraintAssignmentLoading'])

const fetchAssignments = () => {
  const geoRestriction = store.getters['Settings/getGeoRestriction']
  store.dispatch('Assignments/FETCH_STANDARD_ASSIGNMENTS', geoRestriction)
  store.dispatch('Assignments/FETCH_CONSTRAINT_ASSIGNMENTS', geoRestriction)
}

const areasLoading = ref(false)

const fetchAreas = () => {
  store.dispatch('Areas/ADD_AREA_LIST')
}

const companiesLoading = ref(false)

const fetchCompanies = () => {
  store.dispatch('Companies/ADD_COMPANY_LIST')
}

watch(() => store.getters['Auth/isAuth'], (value) => {
  if (value) {
    fetchAreas()
    fetchCompanies()
  }
})

onMounted(() => {
  fetchAreas()
  fetchCompanies()
})
</script>

<style scoped>

</style>
