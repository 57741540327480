import type {AxiosPromise} from 'axios'
import axiosWithAuth from '@/api/axios'

export interface Test {
  text: string
}

const test = {
  fetchTest: (): AxiosPromise<Test> => {
    return axiosWithAuth.get('/test/')
  },
}

export default test
