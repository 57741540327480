import type {MutationTree} from 'vuex'
import type {Company} from '@/api/modules/companies'
import type {CompanyState} from '@/store/modules/companies/index'

const mutations: MutationTree<CompanyState> = {
  ADD_COMPANY_LIST: (state, payload: Array<Company>) => {
    state.data = payload
  },
  SET_LOADING: (state, payload: boolean) => {
    state.loading = payload
  },
}

export default mutations
