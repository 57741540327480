import {createStore} from 'vuex'
import Areas from '@/store/modules/areas'
import Assignments from '@/store/modules/assignments'
import Auth from '@/store/modules/auth'
import BoundConditions from '@/store/modules/boundConditions'
import Companies from '@/store/modules/companies'
import Settings from '@/store/modules/settings'

export interface RootState {
  pendingRequests: number
}

export const defaultState = (): RootState => {
  return {
    pendingRequests: 0,
  }
}

export default createStore<RootState>({
  state: defaultState(),
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  modules: {
    Areas,
    Assignments,
    Auth,
    BoundConditions,
    Companies,
    Settings,
  },
})
