import type {Module} from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import type {RootState} from '@/store'

export interface SettingsState {
  country: string
  year: string
  geoRestrict: boolean
}

export const defaultState = (): SettingsState => {
  return {
    country: '',
    year: '',
    geoRestrict: false,
  }
}

const namespaced = true

const Settings: Module<SettingsState, RootState> = {
  namespaced,
  state: defaultState(),
  getters,
  actions,
  mutations,
}

export default Settings
