import type {ExtractPropTypes, PropType} from 'vue'

export interface Item {
  label: string
  tooltip: number // prop trigger: solid, onHover, ...
  values: Record<string, number>
}

export type ChartTypes = 'stacked' | 'grouped'

export const barchartProps = {
  type: {
    type: String as PropType<ChartTypes>,
    default: 'stacked',
  },
  data: {
    type: Array as PropType<Item[]>,
    default: () => [],
    required: true,
  },
  // if given array is shorter than len(keys), it will be repeated
  barWidth: {
    type: Array as PropType<number[]>,
    default: [16],
  },
  barColors: {
    type: Array as PropType<string[]>,
    default: [''],
  },
  barPaddingInner: {
    type: Number as PropType<number>,
    default: 5,
  },
  // option to add additional space between bargroups
  // has no effect if data wouldn't fill the containers width and therefore gets positioned properly
  barPaddingOuter: {
    type: Number as PropType<number>,
    default: 5,
  },
  hideLegend: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  legendColor: {
    type: String as PropType<string>,
    default: 'text-gray-700',
  },
  legendLabelColor: {
    type: String as PropType<string>,
    default: 'text-gray-600',
  },
  yMin: {
    type: Number as PropType<number>,
    default: 0,
  },
  tooltipColor: {
    type: String as PropType<string>,
    default: '#d30f4b',
  },
  tooltipFormat: {
    type: Function as PropType<(val: number) => string>,
    default: (val: number) => {
      return `${Math.round(val)}`
    },
  },
}

export type BarChartProps = ExtractPropTypes<typeof barchartProps>
