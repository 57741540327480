import type {GetterTree} from 'vuex'
import type {RootState} from '@/store'
import type {AreaState} from '@/store/modules/areas/index'
import type {Area} from '@/api/modules/areas'

const getters: GetterTree<AreaState, RootState> = {
  getAreaList: (state): Array<Area> => state.data,
  getAreaName: state => (id: Area['id']): string => state.data.find(area => area.id === id)?.name ?? '',
  areasLoading: (state): boolean => state.loading,
}

export default getters
