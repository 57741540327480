import type {MutationTree} from 'vuex'
import type {BoundConditionState} from '@/store/modules/boundConditions/index'
import type {BoundCondition} from '@/api/modules/boundConditions'

const mutations: MutationTree<BoundConditionState> = {
  SET_BOUND_CONDITION_LIST: (state, payload: Array<BoundCondition>) => {
    state.data = payload
  },
  SET_LOADING: (state, payload: boolean) => {
    state.loading = payload
  },
  ADD_BOUND_CONDITION: (state, payload: BoundCondition) => {
    state.data.push(payload)
  },
  DELETE_BOUND_CONDITION: (state, id: BoundCondition['id']) => {
    state.data.splice(state.data.findIndex(el => el.id === id), 1)
  },
}

export default mutations
