import type {ActionTree} from 'vuex'
import type {RootState} from '@/store'
import type {AssignmentState} from '@/store/modules/assignments/index'
import api from '@/api'

const actions: ActionTree<AssignmentState, RootState> = {
  FETCH_STANDARD_ASSIGNMENTS: ({commit}, geoRestrict) => {
    commit('SET_STANDARD_LOADING', true)
    api.assignments.fetchAssignments(false, geoRestrict).then((res) => {
      commit('SET_STANDARD_ASSIGNMENTS', res.data)
      commit('SET_STANDARD_ERROR', '')
    }).catch((error) => {
      commit('SET_STANDARD_ERROR', error.response.data.msg)
    }).finally(() => {
      commit('SET_STANDARD_LOADING', false)
    })
  },
  FETCH_CONSTRAINT_ASSIGNMENTS: ({commit}, geoRestrict) => {
    commit('SET_CONSTRAINT_LOADING', true)
    api.assignments.fetchAssignments(true, geoRestrict).then((res) => {
      commit('SET_CONSTRAINT_ASSIGNMENTS', res.data)
      commit('SET_CONSTRAINT_ERROR', '')
    }).catch((error) => {
      commit('SET_CONSTRAINT_ERROR', error.response.data.msg)
    }).finally(() => {
      commit('SET_CONSTRAINT_LOADING', false)
    })
  },
  RESET_ASSIGNMENTS: ({commit}) => {
    commit('RESET_ASSIGNMENTS')
  },
}

export default actions
