import type {DataTableColumn} from 'naive-ui'
import {NCheckbox} from 'naive-ui'
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import {h} from 'vue'
import type {Area} from '@/api/modules/areas'
import api from '@/api'

type Row = Area

export default () => {
  const {t} = useI18n()

  const store = useStore()

  const getColumns: (assigned: boolean, reloadList: () => void) => Array<DataTableColumn<Row>> = (assigned, reloadList) => {
    return [{
      title: t('components.areas.table.name'),
      key: 'name',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
    },
    {
      title: t('components.areas.table.ratioAsphalt'),
      key: 'ratio_asphalt',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (area: Area) => {
        return area.ratioAsphalt.toLocaleString('de-DE', {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
      },
    },
    {
      title: t('components.areas.table.ratioPavement'),
      key: 'ratio_pavement',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (area: Area) => {
        return area.ratioPavement.toLocaleString('de-DE', {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
      },
    },
    {
      title: t('components.areas.table.ratioGreen'),
      key: 'ratio_green',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (area: Area) => {
        return area.ratioGreen.toLocaleString('de-DE', {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
      },
    },
    {
      title: t('components.areas.table.size'),
      key: 'size',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (area: Area) => {
        return `${area.size.toLocaleString('de-DE')} m`
      },
    },
    {
      title: t('components.areas.table.country'),
      key: 'country',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
    },
    {
      title: t('components.areas.table.assignedTo'),
      key: 'assignedTo',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (area: Area) => {
        return store.getters['Companies/getCompanyName'](area.assignedTo) ? store.getters['Companies/getCompanyName'](area.assignedTo) : '-'
      },
    },
    {
      title: assigned ? t('components.areas.table.actionAssigned') : t('components.areas.table.actionNotAssigned'),
      key: 'action',
      width: 35,
      render: (area: Area) => {
        const constraintType = area.assignedTo ? 'include' : 'ignore_area'
        return h(NCheckbox, {
          defaultChecked: !!area.areaConstraint,
          onUpdateChecked: (checked: boolean) => {
            if (checked) {
              return api.boundConditions.createBoundCondition({type: constraintType, area: area.id}).then(() => {
                reloadList()
              })
            }
            else {
              return api.boundConditions.deleteBoundCondition(area.areaConstraint).then(() => {
                reloadList()
              })
            }
          },
        })
      },
    },
    ]
  }

  return {
    getColumns,
  }
}
