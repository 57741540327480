import {createApp} from 'vue'
import naive from 'naive-ui'
import App from './App.vue'
import './index.scss'
import store from '@/store'
import {i18n} from '@/utils/i18n'

const app = createApp(App)

app.use(i18n)
app.use(naive)
app.use(store)

const meta = document.createElement('meta')
meta.name = 'naive-ui-style'
document.head.appendChild(meta)

app.mount('#app')
