import {useI18n} from 'vue-i18n'
import type {DataTableColumn} from 'naive-ui'
import {h} from 'vue'
import type {Company} from '@/api/modules/companies'
import CapacityColumn from '@/components/companies/CapacityColumn.vue'

type Row = Company

export default () => {
  const {t} = useI18n()

  const getColumns: () => Array<DataTableColumn<Row>> = () => {
    return [{
      title: t('components.companies.table.name'),
      key: 'name',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
    }, {
      title: t('components.companies.table.chargeAsphalt'),
      key: 'chargeAsphalt',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (company: Company) => {
        return company.chargeAsphalt.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      },
    }, {
      title: t('components.companies.table.chargePavement'),
      key: 'chargePavement',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (company: Company) => {
        return company.chargePavement.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      },
    }, {
      title: t('components.companies.table.chargeGreen'),
      key: 'chargeGreen',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (company: Company) => {
        return company.chargeGreen.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      },
    }, {
      title: t('components.companies.table.chargePlanningDocu'),
      key: 'chargePlanningDocu',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (company: Company) => {
        return company.chargePlanningDocu.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      },
    }, {
      title: t('components.companies.table.chargeMaterial'),
      key: 'chargeMaterial',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (company: Company) => {
        return company.chargeMaterial.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      },
    }, {
      title: t('components.companies.table.country'),
      key: 'country',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
    }, {
      title: t('components.companies.table.capacity'),
      key: 'capacity',
      width: 70,
      ellipsis: {
        tooltip: true,
      },
      render: (company: Company) => {
        return h(CapacityColumn, {
          capacities: company.capacity,
        })
      },
    }]
  }

  return {
    getColumns,
  }
}
