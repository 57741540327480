<template>
  <div>
    <n-data-table
      :data="data"
      :columns="columns"
      :max-height="500"
      :loading="loading"
    />
  </div>
</template>

<script lang="ts" setup>
import type {PropType} from 'vue'
import type {Company} from '@/api/modules/companies'
import useCompanies from '@/utils/composables/useCompanies'

defineProps({
  data: {
    type: Array as PropType<Array<Company>>,
    default: () => [],
    required: true,
  },
  fetchList: {
    type: Function as PropType<() => void>,
    default: () => [],
    required: true,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false,
    required: true,
  },
})

const {getColumns} = useCompanies()
const columns = getColumns()
</script>

<style></style>
