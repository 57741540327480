<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-dialog-provider>
      <n-message-provider>
        <MainView />
      </n-message-provider>
    </n-dialog-provider>
  </n-config-provider>
</template>

<script lang="ts" setup>
// import {onMounted} from 'vue'
// import {useStore} from 'vuex'
// import {differenceInMilliseconds, isAfter, parseISO} from 'date-fns'
import MainView from '@/views/MainView.vue'
// import api from '@/api'

const themeOverrides = {
  common: {
    primaryColor: '#E74E0D',
    primaryColorHover: '#EF7D27',
    successColor: '#1b6510',
    infoColor: '#f18e02',
    infoColorHover: '#ffae00',
    errorColor: '#ce332e',
    errorColorHover: '#ff6858',
  },
  Button: {
    textColor: '#FFF',
  },
  Checkbox: {
    colorChecked: '#E74E0D',
    labelPadding: '0 0 0 8px',
  },
}

// const store = useStore()
//
// const checkInterval = 150000 // 2,5 minutes
//
// const refreshToken = () => {
//   const tokenExpireTime = parseISO(store.getters['Auth/expiresOn'])
//   const remainingValidTime = Math.abs(differenceInMilliseconds(new Date(), tokenExpireTime))
//   if (remainingValidTime < (2 * checkInterval) || isAfter(new Date(), tokenExpireTime)) {
//     api.auth.refresh().finally(() => {
//       store
//         .dispatch('Auth/LOGIN')
//         .then((res) => {
//           store.dispatch('Auth/SET_TOKEN', res[0])
//         })
//     })
//   }
// }
//
// onMounted(() => {
//   if (import.meta.env.MODE !== 'development') {
//     store
//       .dispatch('Auth/LOGIN')
//       .then((res) => {
//         store.dispatch('Auth/SET_TOKEN', res[0])
//       })
//
//     setInterval(() => {
//       refreshToken()
//     }, checkInterval)
//   }
// })
</script>

<style scoped>
</style>
