<template>
  <n-button
    type="success"
    ghost
    :loading="loading"
    @click="handleReload"
  >
    {{ t('common.action.reload') }}
  </n-button>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'
import {useMessage} from 'naive-ui'
import {useStore} from 'vuex'
import {ref} from 'vue'
import api from '@/api'

const {t} = useI18n()

const message = useMessage()

const store = useStore()

const loading = ref(false)

const handleReload = ref(() => {
  loading.value = true
  api.updates.triggerAreaMasterUpdate().then((res) => {
    loading.value = false
    message.info(res.data.message, {
      keepAliveOnHover: true,
      duration: 5000,
    })
    store.dispatch('Areas/ADD_AREA_LIST')
    store.dispatch('Companies/ADD_COMPANY_LIST')
    store.dispatch('BoundConditions/SET_BOUND_CONDITION_LIST')
    store.dispatch('Assignments/RESET_ASSIGNMENTS')
  }).catch((error) => {
    loading.value = false
    if (error.response.status === 500) {
      message.info(t('common.info.serverError'), {
        keepAliveOnHover: true,
        duration: 5000,
      })
    }
    else {
      message.info(error.response.data.message, {
        keepAliveOnHover: true,
        duration: 5000,
      })
    }
  })
})
</script>

<style scoped>

</style>
