<template>
  <div>
    <div class="font-semibold text-base my-3">
      {{ t('components.areas.assigned') }}
    </div>
    <n-data-table
      :data="assignedData"
      :columns="assignedColumns"
      :max-height="250"
      :loading="loading"
    />
    <div class="font-semibold text-base my-3">
      {{ t('components.areas.notAssigned') }}
    </div>
    <n-data-table
      :data="nonAssignedData"
      :columns="notAssignedColumns"
      :max-height="250"
      :loading="loading"
    />
  </div>
</template>

<script lang="ts" setup>
import type {PropType} from 'vue'
import {computed} from 'vue'
import {useI18n} from 'vue-i18n'
import type {Area} from '@/api/modules/areas'
import useAreas from '@/utils/composables/useAreas'

const {t} = useI18n()

const props = defineProps({
  data: {
    type: Array as PropType<Array<Area>>,
    default: () => [],
    required: true,
  },
  fetchList: {
    type: Function as PropType<() => void>,
    default: () => [],
    required: true,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false,
    required: true,
  },
})

const {getColumns} = useAreas()
const assignedColumns = getColumns(true, props.fetchList)
const notAssignedColumns = getColumns(false, props.fetchList)

const assignedData = computed(() => {
  return props.data?.filter((area) => {
    return area.assignedTo
  })
})

const nonAssignedData = computed(() => {
  return props.data?.filter((area) => {
    return !area.assignedTo
  })
})

</script>

<style scoped>

</style>
