<template>
  <n-checkbox
    v-model:checked="geoRestricted"
    size="medium"
    class="mt-1"
    @update:checked="handleCheck"
  >
    <div class="text-white">
      {{ t('components.settings.geoRestriction.label') }}
    </div>
  </n-checkbox>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const geoRestricted = ref(false)

const store = useStore()

const handleCheck = ref((checked: boolean) => {
  store.dispatch('Settings/SET_GEO_RESTRICTION', checked)
})
</script>

<style scoped>

</style>
