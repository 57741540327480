import {ref} from 'vue'
import {max, select} from 'd3'
import type {Item} from './interface'

export interface GroupedData {
  group: string
  tooltip: number
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export default () => {
  const drawGroupedBars = (data: Item[],
    svg: any,
    colors: any,
    xScale: any,
    yScale: any,
    barWidthArray: number[],
    barPaddingInner: number,
    groupWidth: number,
    chartWidth: number,
    chartHeight: number,
    subgroupKeys: string[],
    tooltipColor: string,
    tooltipFormat: (val: number) => string) => {
    const groupedData = data.map((d: Item): GroupedData => {
      return {
        group: d.label, tooltip: d.tooltip, ...d.values,
      }
    })

    const barWidthSum = ref(0)
    const xPosition = (i: number): number => {
      if (!i)
        barWidthSum.value = 0

      const barPosition = i * barPaddingInner + barWidthSum.value
      barWidthSum.value += barWidthArray[i]
      return barPosition + (xScale.bandwidth() - groupWidth) / 2
    }
    const subgroupKeyData = (d: typeof groupedData): any => {
      return subgroupKeys.map((key: any) => {
        return {
          key, value: d[key],
        }
      })
    }

    // bars
    const groups = svg.selectAll('g.data')
    groups.selectAll('g.bars')
      .data(groupedData)
      .join('g').attr('class', 'bars')
      .attr('transform', (d: GroupedData) => {
        return `translate(${xScale(d.group)},0)`
      })
      .selectAll('rect')
      .data((d: typeof groupedData) => {
        return subgroupKeyData(d)
      }).join(
        (enter: any) => {
          return enter.append('rect')
            .attr('x', (_d: GroupedData, i: number) => xPosition(i))
            .attr('y', (d: {value: number}) => {
              return yScale(d.value)
            })
            .attr('height', (d: {value: number}) => {
              return chartHeight - yScale(d.value)
            })
            .attr('width', (_d: GroupedData, i: number): number => {
              return barWidthArray[i]
            })
            .attr('fill', (d: {key: string}): string => {
              return colors(d.key)
            })
            .style('opacity', 0)
        },
      ).transition().duration(1000)
      .attr('x', (_d: GroupedData, i: number) => xPosition(i))
      .attr('y', (d: {value: number}) => {
        return yScale(d.value)
      })
      .attr('height', (d: {value: number}) => {
        return chartHeight - yScale(d.value)
      })
      .style('opacity', 1)

    // tooltips
    const fontSize = 12
    const marginBottom = 5
    const rectPadding = 4
    const rectHeight = fontSize + 2 * rectPadding
    const barGroupWidth = groups.select('g.bars').node().getBoundingClientRect().width
    groups.selectAll('g.tooltip')
      .selectAll('text')
      .data(groupedData).join((enter: any) => {
        return enter.append('text')
          .attr('class', 'tooltip-text')
          .attr('x', (d: GroupedData) => {
            return xScale(d.group) + xPosition(0) + barGroupWidth / 2
          })
          .attr('y', (d: typeof groupedData) => {
            const yMax = max(subgroupKeyData(d).map((e: {value: number}) => {
              return e.value
            }))
            return yScale(yMax) - marginBottom - 5
          })
          .text((d: GroupedData) => {
            return tooltipFormat(d.tooltip)
          })
          .style('fill', tooltipColor)
          .style('opacity', 0)
      }, (update: any) => {
        return update.text((d: GroupedData) => {
          return tooltipFormat(d.tooltip)
        })
      },
      )
      .transition().duration(1000)
      .attr('x', (d: GroupedData) => {
        return xScale(d.group) + xPosition(0) + barGroupWidth / 2
      })
      .attr('y', (d: typeof groupedData) => {
        const yMax = max(subgroupKeyData(d).map((e: {key: string; value: number}) => {
          return e.value
        }))
        return yScale(yMax) - marginBottom - 5
      })
      .style('opacity', 1)

    const textWidthArray: number[] = []
    groups.selectAll('g.tooltip')
      .selectAll('.tooltip-text')
      .each((_d: GroupedData, i: number, nodes: any) => {
        textWidthArray.push(select(nodes[i]).node().getComputedTextLength())
      })
    const rectWidth = textWidthArray.map(w => w + 2 * rectPadding)

    groups.selectAll('g.tooltip')
      .selectAll('rect')
      .data(groupedData).join((enter: any) => {
        return enter.append('rect')
          .attr('class', 'tooltip-box')
          .attr('x', (d: GroupedData, i: number) => {
            return xScale(d.group) + xPosition(0) + (barGroupWidth - rectWidth[i]) / 2
          })
          .attr('y', (d: typeof groupedData) => {
            const yMax = max(subgroupKeyData(d).map((e: {key: string; value: number}) => {
              return e.value
            }))
            return yScale(yMax) - rectHeight - marginBottom
          })
          .attr('height', rectHeight)
          .attr('width', (_d: GroupedData, i: number) => {
            return rectWidth[i]
          })
          .style('fill', 'white')
          .style('opacity', 0)
      }, (update: any) => {
        return update
      },
      )
      .transition().duration(1000)
      .attr('x', (d: GroupedData, i: number) => {
        return xScale(d.group) + xPosition(0) + (barGroupWidth - rectWidth[i]) / 2
      })
      .attr('y', (d: typeof groupedData) => {
        const yMax = max(subgroupKeyData(d).map((e: {key: string; value: number}) => {
          return e.value
        }))
        return yScale(yMax) - rectHeight - marginBottom
      })
      .attr('width', (_d: GroupedData, i: number) => {
        return rectWidth[i]
      })
      .style('opacity', 1)

    groups.selectAll('g.tooltip').selectAll('.tooltip-text')
      .attr('transform', (_d: GroupedData, i: number) => {
        const offset = -textWidthArray[i] / 2
        return `translate(${offset},0)`
      }).raise()
  }

  return {drawGroupedBars}
}
