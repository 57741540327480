import {select, stack} from 'd3'
import type {Item} from './interface'
import type {GroupedData} from './groupedBars'

export interface StackedData {
  data: {
    group: string
    tooltip: string
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export default () => {
  const drawStackedBars = (data: Item[],
    svg: any,
    colors: any,
    xScale: any,
    yScale: any,
    barWidth: number,
    barPaddingInner: number,
    groupWidth: number,
    chartWidth: number,
    chartHeight: number,
    subgroupKeys: string[],
    tooltipColor: string,
    tooltipFormat: (val: number) => string) => {
    const stackedData = stack().keys(subgroupKeys)(data.map((d: any) => {
      return {
        group: d.label, tooltip: tooltipFormat(d.tooltip), ...d.values,
      }
    }))

    const xPosition = (d: StackedData): number => {
      const barPosition = xScale(d.data.group)
      return barPosition + (xScale.bandwidth() - barWidth) / 2
    }

    // bars
    const stacks = svg.selectAll('g.data')
    stacks.selectAll('g.bars')
      .data(stackedData)
      .join('g').attr('class', 'bars')
      .attr('fill', (d: {key: string}): string => {
        return colors(d.key)
      })
      .selectAll('rect')
      .data((d: typeof stackedData) => {
        return d
      }).join((enter: any) => {
        return enter.append('rect')
          .attr('x', xPosition)
          .attr('y', (d: number[]) => {
            return yScale(d[1])
          })
          .attr('height', (d: number[]) => {
            return yScale(d[0]) - yScale(d[1])
          })
          .attr('width', barWidth)
          .style('opacity', 0)
      },
      )
      .transition().duration(1000)
      .attr('x', (d: StackedData) => {
        return xPosition(d)
      })
      .attr('y', (d: number[]) => {
        return yScale(d[1])
      })
      .attr('height', (d: number[]) => {
        return yScale(d[0]) - yScale(d[1])
      })
      .style('opacity', 1)

    // tooltips
    const tooltipData = stackedData[stackedData.length - 1]
    const fontSize = 12
    const marginBottom = 5
    const rectPadding = 4
    const rectHeight = fontSize + 2 * rectPadding
    stacks.selectAll('g.tooltip')
      .selectAll('text')
      .data(tooltipData).join((enter: any) => {
        return enter.append('text')
          .attr('class', 'tooltip-text')
          .attr('x', (d: StackedData) => {
            return xPosition(d)
          })
          .attr('y', (d: number[]) => {
            return yScale(d[1]) - marginBottom - 5
          })
          .text((d: StackedData) => {
            return d.data.tooltip
          })
          .style('fill', tooltipColor)
          .style('opacity', 0)
      }, (update: any) => {
        return update.text((d: StackedData) => {
          return d.data.tooltip
        })
      },
      )
      .transition().duration(1000)
      .attr('x', (d: StackedData) => {
        return xPosition(d)
      })
      .attr('y', (d: number[]) => {
        return yScale(d[1]) - marginBottom - 5
      })
      .style('opacity', 1)

    const textWidthArray: number[] = []
    stacks.selectAll('g.tooltip')
      .selectAll('.tooltip-text')
      .each((_d: GroupedData, i: number, nodes: any) => {
        textWidthArray.push(select(nodes[i]).node().getComputedTextLength())
      })
    const rectWidth = textWidthArray.map(w => w + 2 * rectPadding)

    stacks.selectAll('g.tooltip')
      .selectAll('rect')
      .data(tooltipData).join((enter: any) => {
        return enter.append('rect')
          .attr('class', 'tooltip-box')
          .attr('x', (d: StackedData, i: number) => {
            return xPosition(d) + (barWidth - rectWidth[i]) / 2
          })
          .attr('y', (d: number[]) => {
            return yScale(d[1]) - rectHeight - marginBottom
          })
          .attr('height', rectHeight)
          .attr('width', (_d: GroupedData, i: number) => {
            return rectWidth[i]
          })
          .style('fill', 'white')
          .style('opacity', 0)
      }, (update: any) => {
        return update
      },
      )
      .transition().duration(1000)
      .attr('x', (d: StackedData, i: number) => {
        return xPosition(d) + (barWidth - rectWidth[i]) / 2
      })
      .attr('y', (d: number[]) => {
        return yScale(d[1]) - rectHeight - marginBottom
      })
      .attr('width', (_d: GroupedData, i: number) => {
        return rectWidth[i]
      })
      .style('opacity', 1)

    stacks.selectAll('g.tooltip').selectAll('.tooltip-text')
      .attr('transform', (_d: GroupedData, i: number) => {
        const offset = (barWidth - rectWidth[i]) / 2
        return `translate(${offset + rectPadding},0)`
      }).raise()
  }

  return {drawStackedBars}
}
