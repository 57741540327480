import type {ActionTree} from 'vuex'
import type {RootState} from '@/store'
import type {AreaState} from '@/store/modules/areas/index'
import api from '@/api'

const actions: ActionTree<AreaState, RootState> = {
  ADD_AREA_LIST: ({commit}) => {
    commit('SET_LOADING', true)
    api.areas.fetchAreas().then((res) => {
      commit('ADD_AREA_LIST', res.data)
    }).finally(() => {
      commit('SET_LOADING', false)
    })
  },
}

export default actions
